// Local Redux
import {SET_TURN_OFF_REASONS} from '../actionTypes';


const initialState = {
	turnOffReasons: [],
};

const reducerMenu = (state = initialState, {type, data}) => {
	switch (type) {
		case SET_TURN_OFF_REASONS:
			return {...state, turnOffReasons: data};
		default:
			return state;
	}
};

export default reducerMenu;
