import {useState} from 'react';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Group, Menu, Modal, Stack} from '@mantine/core';

// Redux
import {useSelector, useDispatch} from 'react-redux';
import {setUserData} from 'store/actions';

// Fontawesome
import {faChevronDown, faArrowRightFromBracket, faStore, faDoorOpen} from '@fortawesome/pro-solid-svg-icons';

// Global components
import Text from 'components/core/Text';
import Button from 'components/core/Button';
import SearchInput from 'components/core/Input/SearchInput';
import FaDisplay from 'components/others/FaDisplay';

// Global utils
import {lowercasedIncludes} from 'utils';
import AuthUtils from 'utils/auth';

// Local styles
import {useStyles} from './styles';

const Outlet = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {t} = useTranslation('translation', {keyPrefix: 'global.header'});
	const {classes, theme} = useStyles();
	const [search, setSearch] = useState('');
	const [changeOutletModalOpen, setChangeOutletModalOpen] = useState(false);
	const {selectedLocation, isAdmin, details} = useSelector(({reducerUser}) => reducerUser) || {};

	const menus = [
		...(isAdmin ? [{
			label: t('changeOutlet'),
			icon: faStore,
			onClick: () => setChangeOutletModalOpen(true),
		}] : []),
		{
			label: t('manageOutletOnOff'),
			icon: faDoorOpen,
			onClick: () => navigate('/outlet-status'),
		},
		{
			label: t('logout'),
			icon: faArrowRightFromBracket,
			onClick: () => AuthUtils.revokeCurrentSession().catch(() => null).finally(() => navigate('/auth', {replace: true})),
		},
	];

	const displayedOutlets = details?.locations?.filter(location => lowercasedIncludes(location.label, search)) || [];

	// Render
	return (
		<>
			<Menu
				transition="scale"
				shadow={theme.shadows.esopqu}
				control={(
					<Group
						spacing={4}
						noWrap
						style={{cursor: 'context-menu'}}>
						<FaDisplay
							containerSize={18}
							fontSize={14}
							faIcon={faStore}
							color={theme.colors.light.primary.default} />
						<Text
							typography="title-bold-16"
							align="center"
							transform="capitalize">
							{selectedLocation?.label ? t('outlet', {name: selectedLocation.label}) : t('noOutlet')}
						</Text>
						<FaDisplay
							containerSize={18}
							fontSize={14}
							faIcon={faChevronDown} />
					</Group>
				)}
			>
				<Menu.Label p="4px 8px">
					<Text
						typography="subtitle-medium-16"
						color={theme.colors.light.text.secondary}>
						{t('accountTitle')}
					</Text>
				</Menu.Label>
				{menus.map(({label, icon, onClick}, i) => (
					<Menu.Item
						p="4px 8px"
						key={i}
						onClick={onClick}>
						<Group spacing={8}>
							<FaDisplay
								containerSize={18}
								fontSize={14}
								faIcon={icon}
							/>
							<Text typography="title-bold-16">{label}</Text>
						</Group>
					</Menu.Item>
				))}
			</Menu>
			{isAdmin && (
				<Modal
					centered
					opened={changeOutletModalOpen}
					withCloseButton={false}
					classNames={{
						modal: classes.modalCard,
						body: classes.modalBody,
					}}
					onClose={() => setChangeOutletModalOpen(false)}>
					<Text
						mt={24}
						typography="title-bold-18">
						{t('changeOutlet')}
					</Text>
					<SearchInput
						mt={16}
						width={352}
						placeholer={t('searchOutletPlacheholder')}
						onChangeSearch={setSearch}
					/>
					<Stack
						mt={12}
						spacing={0}
						className={classes.changeOutletContainer}>
						{displayedOutlets.map(location => (
							<Group
								py={8}
								key={location.id}
								position="apart"
								className={classes.changeOutletRow} >
								<Text
									typography="body-regular-16">
									{t('outlet', {name: location.label})}
								</Text>
								<Button onClick={() => {
									dispatch(setUserData({selectedLocation: location}));
									setTimeout(() =>
										window.location.reload()
									, 100);
								}}>
									{t('chooseOutlet')}
								</Button>
							</Group>
						))}
					</Stack>
				</Modal>
			)}
		</>
	);
};

Outlet.defaultProps = {
};

Outlet.propTypes = {
};

export default Outlet;
