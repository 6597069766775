import {createStyles} from '@mantine/core';

export const useStyles = createStyles(() => ({
	header: {
		padding: '24px 32px 16px',
	},
	column: {
		flex: 1,
		maxWidth: 'unset',
		display: 'flex',
		alignItems: 'center',

		'&:nth-of-type(2)': {
			flex: 2,
			justifyContent: 'center',
			minWidth: 400,
		},
		'&:last-child': {
			justifyContent: 'flex-end',
		},
	},
	button: {
		width: 115,
		padding: 0,
	},
}));