import {useRef, useState} from 'react';
import {TextInput} from '@mantine/core';
import {useDidUpdate} from '@mantine/hooks';
import PropTypes from 'prop-types';

// Fontawesome
import {faXmark, faSearch} from '@fortawesome/pro-regular-svg-icons';

// Global components
import FaDisplay from 'components/others/FaDisplay';

// Local styles
import {useStyles} from './styles';

const SearchInput = ({placeholer, width, onChangeSearch, ...rest}) => {
	const inputRef = useRef();
	const {classes, theme} = useStyles();
	const [value, setValue] = useState('');
	// Lifecycle Hooks
	useDidUpdate(() => {
		onChangeSearch(value);
	}, [value]);
	// Render
	return (
		<TextInput
			ref={inputRef}
			size="sm"
			style={{width}}
			classNames={{wrapper: classes.searchWrapper}}
			icon={(
				<FaDisplay
					faIcon={faSearch}
					containerSize={20}
					fontSize={18} />
			)}
			rightSection={value && (
				<FaDisplay
					faIcon={faXmark}
					containerSize={16}
					fontSize={14}
					color={theme.colors.light.text.secondary}
					onClick={() => {
						inputRef.current.value = '';
						setValue('');
					}}
					style={{cursor: 'pointer'}} />
			)}
			onChange={e => setValue(e.target.value)}
			placeholder={placeholer}
			{...rest}
		/>
	);
};

SearchInput.defaultProps = {
	placeholer: 'Search',
	width: 320,
	onChangeSearch: () => null,
};

SearchInput.propTypes = {
	placeholer: PropTypes.string,
	width: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]),
	onChangeSearch: PropTypes.func,
};

export default SearchInput;
