import Header from 'components/others/Header';
import RequireAuthRoute from 'components/others/RequireAuthRoute';
import {Navigate} from 'react-router';

import MenuTabBar from 'views/Menu/components/MenuTabBar';

export default [
	{
		path: '/outlet/:locationId',
		element: 'views/Auth/SaveLocationId',
	},
	{
		path: '/admin',
		element: 'views/Auth/Login',
	},
	{
		path: '/auth',
		element: 'views/Auth/Login',
	},
	{
		path: '/',
		element: <RequireAuthRoute><Header /></RequireAuthRoute>,
		children: [
			{path: '/', element: 'views/Orders'},
			{path: '/outlet-status', element: 'views/OutletOnOff'},
			{
				path: '/menu',
				element: <MenuTabBar />,
				children: [
					{path: '/menu', element: 'views/Menu/MainMenu'},
					{path: '/menu/complex', element: 'views/Menu/ComplexMenu'},
					{path: '/menu/material', element: 'views/Menu/Material'},
					{path: '/menu/request-status', element: 'views/Menu/RequestStatus'},
					{path: '/menu/request-status/complex', element: <Navigate
						to='/menu/request-status'
						state={{focusToComplexTab: true}} />},
				],
			},
			{
				path: '/recon',
				children: [
					{path: '/recon', element: 'views/Reconciliation/ReconciliationSummary'},
					{path: '/recon/details', element: 'views/Reconciliation/ReconciliationDetails'},
				],
			},
		],
	},
	{redirect: true},
];
