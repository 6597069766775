// Global utils
import AuthUtils from 'utils/auth';

// Request utils
import request, {REQUEST_ENV} from 'request';

export const authCheckRequest = async () => {
	return request({
		method: 'get',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/auth/me`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
	});
};

export const loginAdminUserRequest = async (payload, setState) => {
	// payload: {username, password]}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.ORDERHUB_API_HOST}/auth/login`,
	  data: payload,
	}, setState);
};

export const loginUserRequest = async (payload, setState) => {
	// payload: {username, pin]}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.ORDERHUB_API_HOST}/auth/login/resto`,
	  data: payload,
	}, setState);
};

export const logoutUserRequest = async setState => {
	return request({
		method: 'post',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/auth/logout`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	}, setState);
};