import {Button as MantineButton} from '@mantine/core';
import {AnimatePresence, motion} from 'framer-motion';
import PropTypes from 'prop-types';

// Fontawesome
import {faLoader} from '@fortawesome/pro-duotone-svg-icons';

// Global components
import FaDisplay from 'components/others/FaDisplay';

// Local styles
import {useStyles} from './styles';

const SIZE_MAPPING = {
	sm: {
		height: 34,
		fontSize: 12,
	},
	md: {
		height: 40,
		fontSize: 14,
	},
	lg: {
		height: 48,
		fontSize: 16,
	},
};

const Button = ({size, className, classNames, loading, disabled, children, ...rest}) => {
	const {classes, cx} = useStyles({
		height: SIZE_MAPPING[size].height,
		fontSize: SIZE_MAPPING[size].fontSize,
	});

	// Render
	return (
		<MantineButton
			className={cx(
				classes.root,
				className,
			)}
			classNames={{
				leftIcon: classes.leftIcon,
				rightIcon: classes.rightIcon,
				...classNames,
			}}
			disabled={disabled || loading}
			{...rest}>
			<AnimatePresence>
				{loading && (
					<motion.div
						initial={{scale: 0, width: 0}}
						animate={{scale: 1, width: 'auto'}}
						exit={{scale: 0, width: 0}}
						transition={{duration: 0.25}}
					>
						<FaDisplay
							mr={4}
							containerSize={18}
							fontSize={14}
							faIcon={faLoader}
							faProps={{spin: true}} />
					</motion.div>
				)}
			</AnimatePresence>
			{children}
		</MantineButton>
	);
};

Button.defaultProps = {
	size: 'md',
	variant: 'filled',
	loading: false,
	disabled: false,
};

Button.propTypes = {
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	variant: PropTypes.oneOf(['filled', 'light', 'outline', 'default']),
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	classNames: PropTypes.object,
	children: PropTypes.node,
};

export default Button;
