import {Center, Group, Loader} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

// Global components
import Text from 'components/core/Text';

// Local styles
import {useStyles} from './styles';

const ServiceStatus = () => {
	const {t} = useTranslation('translation', {keyPrefix: 'global.header.status'});
	const isOnline = useSelector(({reducerOrder}) => reducerOrder.isServerOnline);
	const {classes, cx, theme} = useStyles();

	// Render
	return (
		<Group
			spacing={4}
			style={{width: 128}}
			noWrap>
			{isOnline !== null ? (
				<>
					<Center className={cx(classes.indicatorBullet, {[classes.indicatorBulletActive]: isOnline})}>
						<span />
					</Center>
					<Text
						typography="title-bold-16"
						className={cx(classes.statusText, {[classes.statusTextActive]: isOnline})}>
						{t(isOnline ? 'online' : 'offline')}
					</Text>
				</>
			) : (
				<>
					<Loader
						size={14}
						color={theme.colors.light.text.secondary} />
					<Text
						typography="title-bold-16"
						className={classes.statusText}
						color={theme.colors.light.text.secondary}>
						Loading...
					</Text>
				</>
			)}
		</Group>
	);
};

export default ServiceStatus;
