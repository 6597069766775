import {createStyles} from '@mantine/core';

export const useStyles = createStyles((theme, {height, fontSize}) => ({
	root: {
		height,
		fontSize,
		padding: '0 12px',
		fontWeight: 700,
		borderRadius: 8,
		boxShadow: theme.shadows.xs,
		transition: '250ms',
		'&::before': {
			content: 'none',
		},
		'&.mantine-Button-filled': {
			backgroundColor: theme.colors.light.primary.default,
			color: theme.colors.light.neutral.primary,
			'&:hover': {
				backgroundColor: theme.colors.light.primary.hover,
			},
			'&:active': {
				boxShadow: `${theme.shadows.xs}, 0px 0px 0px 4px ${theme.colors.light.primary.background}`,
			},
			'&:disabled': {
				backgroundColor: theme.colors.light.text.disabled,
				color: theme.colors.light.neutral.primary,
				filter: 'unset',
				boxShadow: 'unset',
				'#buttonLoader': {
					stroke: theme.colors.light.neutral.primary,
				},
			},
		},
		'&.mantine-Button-light': {
			backgroundColor: theme.colors.light.neutral.primary,
			color: theme.colors.light.primary.default,
			'&:hover': {
				backgroundColor: theme.colors.light.primary.background,
			},
			'&:disabled': {
				backgroundColor: theme.colors.light.text.disabled,
				color: theme.colors.light.neutral.primary,
				filter: 'unset',
				'#buttonLoader': {
					stroke: theme.colors.light.neutral.primary,
				},
			},
		},
		'&.mantine-Button-outline': {
			color: theme.colors.light.primary.default,
			borderColor: theme.colors.light.primary.default,
			'&:hover': {
				backgroundColor: theme.colors.light.primary.background,
			},
			'&:active': {
				boxShadow: `${theme.shadows.xs}, 0px 0px 0px 4px ${theme.colors.light.primary.background}`,
			},
			'&:disabled': {
				backgroundColor: 'transparent',
				color: theme.colors.light.text.disabled,
				borderColor: theme.colors.light.text.disabled,
				filter: 'unset',
				boxShadow: 'unset',
				'#buttonLoader': {
					stroke: theme.colors.light.text.disabled,
				},
			},
		},
		'&.mantine-Button-default': {
			backgroundColor: theme.colors.light.neutral.primary,
			borderColor: theme.colors.light.neutral.border,
			'&:hover': {
				backgroundColor: theme.colors.light.neutral.secondary,
			},
			'&:active': {
				boxShadow: `${theme.shadows.xs}, 0px 0px 0px 4px ${theme.colors.light.neutral.border}`,
			},
			'&:disabled': {
				backgroundColor: theme.colors.light.neutral.primary,
				borderColor: theme.colors.light.neutral.border,
				color: theme.colors.light.text.disabled,
				boxShadow: 'unset',
				'#buttonLoader': {
					stroke: theme.colors.light.text.disabled,
				},
			},
		},
	},
	leftIcon: {
		marginRight: 4,
	},
	rightIcon: {
		marginLeft: 4,
	},
}));
