import {createStyles} from '@mantine/core';

export const useStyles = createStyles((theme, {color, size, iconSize, borderSize}) => {
	const surroundedCircleSize = {
		width: size - borderSize / 2,
		height: size - borderSize / 2,
	};
	return ({
		container: {
			width: size,
			height: size,
			svg: {
				fontSize: iconSize,
			},
		},
		innerContainer: {
			width: size,
			height: size,
			borderRadius: '50%',
		},
		normal: {
			backgroundColor: color === 'neutral'
				? theme.colors.light.neutral.secondary
				: theme.colors.light[color]?.background,
			color: color === 'neutral'
				? theme.colors.light.text.tertiary
				: theme.colors.light[color]?.default,
		},
		lightCircle: {
			...surroundedCircleSize,
			boxShadow: `0 0 0 ${borderSize}px ${color === 'neutral'
				? theme.colors.light.neutral.secondary
				: theme.colors.light[color]?.background}`,
			backgroundColor: color === 'neutral'
				? theme.colors.light.neutral.border
				: theme.colors.light[color]?.border,
			color: color === 'neutral'
				? theme.colors.light.text.tertiary
				: theme.colors.light[color]?.default,
		},
		darkCircle: {
			...surroundedCircleSize,
			boxShadow: `0 0 0 ${borderSize}px ${color === 'neutral'
				? theme.colors.light.text.secondary
				: theme.colors.light[color]?.hover}`,
			backgroundColor: color === 'neutral'
				? theme.colors.light.text.tertiary
				: theme.colors.light[color]?.default,
			color: theme.colors.light.neutral.primary,
		},
		lightSquare: {
			borderRadius: 8,
			backgroundColor: color === 'neutral'
				? theme.colors.light.neutral.secondary
				: theme.colors.light[color]?.background,
			color: color === 'neutral'
				? theme.colors.light.text.tertiary
				: theme.colors.light[color]?.default,
		},
		midSquare: {
			borderRadius: 8,
			backgroundColor: color === 'neutral'
				? theme.colors.light.text.tertiary
				: theme.colors.light[color]?.default,
			color: theme.colors.light.neutral.primary,
		},
		darkSquare: {
			borderRadius: 8,
			backgroundColor: color === 'neutral'
				? theme.colors.light.text.secondary
				: theme.colors.light[color]?.pressed,
			color: theme.colors.light.neutral.primary,
		},
		spin: {
			animation: 'spin 2.5s linear infinite',
			'@keyframes spin': {
				'from': {transform: 'rotate(0deg)'},
				'to ': {transform: 'rotate(360deg)'},
			},
		},
	});
});
