const Modal = theme => ({
	overlay: {
		backgroundColor: `${theme.colors.light.modal.overlayBlack} !important`,
		opacity: '1 !important',
	},
	modal: {
		borderRadius: 8,
	},
});

export {Modal};