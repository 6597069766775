import {createStyles} from '@mantine/core';

export const useStyles = createStyles(
	(theme, {color, bordered, textTransform = 'capitalize'}) => ({
		badge: {
			fontWeight: 700,
			textTransform,
			backgroundColor:
        theme.colors.light[color][color === 'neutral' ? 'secondary' : 'background'],
			color:
        theme.colors.light[color === 'neutral' ? 'text' : color][
        	color === 'neutral'
        		? 'secondary'
        		: color === 'warning'
        			? 'hover'
        			: 'default'
        ],
			...(bordered && {borderColor: theme.colors.light[color].border}),
		},
		sm: {
			height: 22,
			fontSize: 12,
			lineHeight: '18px',
			padding: '0 8px',
		},
		md: {
			height: 24,
			fontSize: 14,
			lineHeight: '20px',
			padding: '0 10px',
		},
		lg: {
			height: 28,
			fontSize: 14,
			lineHeight: '20px',
			padding: '0 12px',
		},
	}),
);
