import {hideNotification, showNotification, updateNotification} from '@mantine/notifications';
import i18n from 'i18next';

// Fontawesome
import {faCheckCircle, faExclamationCircle, faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {faLoader} from '@fortawesome/pro-duotone-svg-icons';

// Global components
import FeaturedIcon from 'components/others/FeaturedIcon';

// Global config
import theme from 'config/mantineTheme';

const sharedConfigs = ({title, message, extra, ...rest}) => ({
	title,
	message: <>
		{message}
		{extra}
	</>,
	autoClose: 5000,
	styles: {
		root: {
			padding: '16px !important',
			alignItems: message ? 'flex-start' : 'center',
			borderRadius: 8,
			maxHeight: 'unset !important',
			// Mantine's notification animates pop up transition and notification height
			// by unsettin the max-height, the notification height transition will not work
			// which is in fact in our favor as as animating card while content already overflowed are weird
			'&::before': {content: 'none'},
		},
		icon: {
			width: 'unset',
			height: 'unset',
			marginRight: 12,
			backgroundColor: 'unset !important',
		},
		body: {
			marginRight: 12,
			maxWidth: 'unset',
		},
		title: {
			fontSize: 14,
			lineHeight: '20px',
			fontWeight: 700,
			color: theme.colors.light.text.primary,
			marginBottom: message ? 2 : 0,
		},
		description: {
			fontSize: 12,
			lineHeight: '18px',
			color: theme.colors.light.text.secondary,
		},
		closeButton: {
			minWidth: 20,
			minHeight: 20,
			width: 20,
			height: 20,
			svg: {
				width: 18,
				height: 18,
				color: theme.colors.light.text.tertiary,
				stroke: theme.colors.light.text.tertiary,
				strokeWidth: 0.5,
			},
		},
	},
	...rest,
});

export const success = ({isUpdate, ...rest}) => {
	const notificationFunction = isUpdate ? updateNotification : showNotification;
	notificationFunction({
		icon: <FeaturedIcon
			faIcon={faCheckCircle}
			size="md"
			color="success" />,
		...sharedConfigs(rest),
	});
};
export const warning = ({isUpdate, ...rest}) => {
	const notificationFunction = isUpdate ? updateNotification : showNotification;
	notificationFunction({
		icon: <FeaturedIcon
			faIcon={faExclamationTriangle}
			size="md"
			color="warning" />,
		...sharedConfigs(rest),
	});
};
export const error = ({isUpdate, ...rest}) => {
	const notificationFunction = isUpdate ? updateNotification : showNotification;
	notificationFunction({
		icon: <FeaturedIcon
			faIcon={faExclamationCircle}
			size="md"
			color="error"
		/>,
		...sharedConfigs(rest),
	});
};
export const loading = ({isUpdate, ...rest}) => {
	const notificationFunction = isUpdate ? updateNotification : showNotification;
	notificationFunction({
		icon: <FeaturedIcon
			faIcon={faLoader}
			size="md"
			color="neutral"
			isSpin />,
		...sharedConfigs(rest),
	});
};
export const normal = ({isUpdate, ...rest}) => {
	const notificationFunction = isUpdate ? updateNotification : showNotification;
	notificationFunction({
		...sharedConfigs(rest),
	});
};
export const generalError = e => {
	if (e?.response?.status === 401) {
		return error(i18n.t('global.unauthorizedErrorToast'));
	}
	error({
		title: i18n.t('global.unknownErrorToast'),
		message: i18n.t('global.unknownErrorToastSubtitle'),
	});
};
export const hide = id => {
	hideNotification(id);
};

export default {success, warning, error, normal, loading, generalError, hide};