import React from 'react';
import {createRoot} from 'react-dom/client';
import {PersistGate} from 'redux-persist/integration/react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import Bugsnag from '@bugsnag/js';

// Global utils
import 'utils/i18n';
import crashLogger from 'utils/logger';

// Redux
import {store, persistor} from 'store';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const ErrorBoundary = crashLogger.bugsnagEnabled
	? Bugsnag.getPlugin('react').createErrorBoundary(React)
	: React.Fragment;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<ErrorBoundary>
	<Provider store={store}>
		<PersistGate
			loading={null}
			persistor={persistor}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>
</ErrorBoundary>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
