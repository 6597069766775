import {createStyles} from '@mantine/core';

export const useStyles = createStyles((theme, {indicatorColor}) => ({
	indicatorBullet: {
		width: 14,
		height: 14,
		borderRadius: '50%',
		backgroundColor: theme.colors.light[indicatorColor].background,
		'span': {
			width: 8,
			height: 8,
			borderRadius: '50%',
			backgroundColor: theme.colors.light[indicatorColor].default,
		},
	},
	statusText: {
		textTransform: 'capitalize',
		color: theme.colors.light[indicatorColor].default,
		whiteSpace: 'nowrap',
	},
	separator: {
		margin: '0 24px',
		height: 34,
		borderRight: `1px solid ${theme.colors.light.neutral.border}`,
	},
}));