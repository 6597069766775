import dayjs from 'dayjs';

// Local Redux
import {
	SET_IS_SERVER_ONLINE,
	SET_ACTIVE_ORDERS,
	SET_IS_FETCHING_ACTIVE_ORDER,
	SET_HISTORY_DATE,
	SET_HISTORY_ORDERS,
	SET_IS_FETCHING_HISTORY_ORDER,
	SET_ORDER_DRAWER_DATA,
	CLEAR_ORDER_DRAWER_DATA,
	SET_PLATFORMS,
	SET_BRANDS,
} from '../actionTypes';


const initialState = {
	isServerOnline: null,
	activeOrders: [],
	isFetchingActiveOrder: false,
	historyDate: dayjs().startOf('day').subtract(dayjs().hour() < 7 ? 1 : 0, 'day').toDate(),
	historyOrders: [],
	isFetchingHistoryOrder: false,
	drawer: {
		open: false,
		data: {},
		onSubmitManualProcess: null,
		submitManualLoading: false,
		manualType: '',
	},
	platforms: [],
	brands: [],
};

const reducerOrder = (state = initialState, {type, data}) => {
	switch (type) {
		case SET_IS_SERVER_ONLINE:
			return {...state, isServerOnline: data};
		case SET_ACTIVE_ORDERS:
	  return {...state, activeOrders: data};
		case SET_IS_FETCHING_ACTIVE_ORDER:
			return {...state, isFetchingActiveOrder: data};
		case SET_HISTORY_DATE:
	  return {...state, historyDate: data};
		case SET_HISTORY_ORDERS:
	  return {...state, historyOrders: data};
		case SET_IS_FETCHING_HISTORY_ORDER:
			return {...state, isFetchingHistoryOrder: data};
		case SET_ORDER_DRAWER_DATA:
	  return {...state, drawer: {...state.drawer, ...data}};
		case CLEAR_ORDER_DRAWER_DATA:
	  return {...state, drawer: initialState.drawer};
		case SET_PLATFORMS:
	  return {...state, platforms: data};
		case SET_BRANDS:
	  return {...state, brands: data};
		default:
			return state;
	}
};

export default reducerOrder;
