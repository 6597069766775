const sharedStyles = theme => ({
	input: {
		border: `1px solid ${theme.colors.light.text.disabled}`,
		'&:checked': {
			backgroundColor: theme.colors.light.success.default,
			borderColor: theme.colors.light.success.default,
		},
		'&:disabled': {
			backgroundColor: theme.colors.light.neutral.secondary,
			borderColor: theme.colors.light.text.disabled,
		},
	},
	label: {
		paddingLeft: 8,
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 500,
		color: theme.colors.light.text.primary,
	},
	icon: {
		color: theme.colors.light.neutral.primary,
	},
});

const CheckboxGroup = theme => ({
	...sharedStyles(theme),
});

const Checkbox = theme => ({
	...sharedStyles(theme),
});

export {CheckboxGroup, Checkbox};