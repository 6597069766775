import {Group, Tabs} from '@mantine/core';
import PropTypes from 'prop-types';

// Global components
import Text from 'components/core/Text';
import Badge from 'components/core/Badge';

// Local styles
import {useStyles} from './styles';

const TabBar = ({grow, showZero, tabs, activeTab, onTabChange, classNames, ...rest}) => {
	const {classes, cx} = useStyles();

	// Render
	return (
		<Tabs
			active={activeTab}
			onTabChange={onTabChange}
			classNames={{
				tabsList: classes.tabsList,
				tabControl: cx(classes.tabControl, {[classes.tabControlGrow]: grow}),
				...classNames,
			}}
			grow={grow}
			{...rest}>
			{tabs.map(({label, count, content, shake}) => (
				<Tabs.Tab
					key={label}
					label={(
						<Group
							spacing={8}
							className={{[classes.shake]: shake}}>
							<Text typography="title-bold-16">
								{label}
							</Text>
							{(count > 0 || showZero) && (
								<Badge
									color="primary"
									className={classes.badge}>
									{count || 0}
								</Badge>
							)}
						</Group>
					)}>
					{content}
				</Tabs.Tab>
			))}
		</Tabs>
	);
};

TabBar.defaultProps = {
	grow: false,
	showZero: false,
	tabs: [],
	activeTab: 0,
	classNames: {},
	onTabChange: () => null,
};

TabBar.propTypes = {
	grow: PropTypes.bool,
	showZero: PropTypes.bool,
	tabs: PropTypes.array,
	activeTab: PropTypes.number,
	classNames: PropTypes.object,
	onTabChange: PropTypes.func,
};

export default TabBar;
