// Local Redux
import {SET_REMOTE_CONFIG} from '../actionTypes';

const initialState = {};

const reducerRemoteConfig = (state = initialState, {type, data}) => {
	switch (type) {
		case SET_REMOTE_CONFIG:
	  return data;
		default:
			return state;
	}
};

export default reducerRemoteConfig;
