// Global utils
import AuthUtils from 'utils/auth';

// Redux
import {store} from 'store';
import {setOnOffOutlerReasonDuration} from 'store/actions';

// Request utils
import request, {REQUEST_ENV} from 'request';

export const getMerchantList = setState => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/outlet/merchants/${locationId}`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
		},
		setState,
	);
};

export const getTurnOffDuration = () => {
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/outlet/durations`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
		},
	);
};

export const getTurnOffReason = () => {
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/outlet/reasons`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
		},
	);
};

export const getOutletOnOffReasonAndDuration = async () => {
	try {
		const durationResponse = await request(
			{
				method: 'get',
				url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/outlet/durations`,
				headers: {
					Authorization: `Bearer ${AuthUtils.sessionToken}`,
				},
			},
		);

		const reasonResponse = await request(
			{
				method: 'get',
				url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/outlet/reasons`,
				headers: {
					Authorization: `Bearer ${AuthUtils.sessionToken}`,
				},
			},
		);

		store.dispatch(setOnOffOutlerReasonDuration({
			durations: durationResponse.durations,
			reasons: reasonResponse.reasons,
		}));
	} catch {}
};

export const toggleOutletStatus = async payload => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	/**
	 * payload
	 * - merchantIds: Array
	 * - reason: String
	 * - turnOn: Boolean
	 * - duration: Integer (in minutes)
	 */
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/outlet/toggle-state`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {...payload, locationId},
		},
	);
};

export const retryOnOffRequest = async payload => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	/**
	 * payload
	 * - merchantIds: Array
	 */
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/outlet/toggle-state/retry`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {...payload, locationId},
		},
	);
};