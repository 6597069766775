// Local Redux
import {SET_OUTLET_ON_OFF_REASONS_DURATION} from '../actionTypes';


const initialState = {
	durations: [],
	reasons: [],
};

const reducerOutlet = (state = initialState, {type, data}) => {
	switch (type) {
		case SET_OUTLET_ON_OFF_REASONS_DURATION:
			return {...state, durations: data.durations, reasons: data.reasons};
		default:
			return state;
	}
};

export default reducerOutlet;