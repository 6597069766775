import {Center} from '@mantine/core';
import PropTypes from 'prop-types';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const FaDisplay = ({
	faIcon,
	containerSize,
	fontSize,
	color,
	faProps,
	...rest
}) => {
	// Render
	return (
		<Center
			style={{
				cursor: rest.onClick ? 'pointer' : 'default',
				width: containerSize,
				height: containerSize,
			}}
			{...rest}
		>
			<FontAwesomeIcon
				style={{
					fontSize,
				}}
				icon={faIcon}
				color={color}
				{...faProps} />
		</Center>
	);
};

FaDisplay.defaultProps = {
	containerSize: 24,
	fontSize: 16,
	faProps: {},
};

FaDisplay.propTypes = {
	containerSize: PropTypes.number,
	fontSize: PropTypes.number,
	color: PropTypes.string,
	faIcon: PropTypes.object,
	faProps: PropTypes.object,
};

export default FaDisplay;
