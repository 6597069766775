import {Box} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

// Global components
import TabBar from 'components/others/TabBar';

// Local styles
import {useStyles} from './styles';

const MenuSidebar = () => {
	const {t} = useTranslation('translation', {keyPrefix: 'pages.menu.components.menuSidebar'});
	const {classes} = useStyles();
	const navigate = useNavigate();
	const location = useLocation();

	const menus = [
		{label: t('mainMenu'), path: '/menu'},
		{label: t('complexMenu'), path: '/menu/complex'},
		{label: t('material'), path: '/menu/material'},
		{label: t('requestStatus'), path: '/menu/request-status'},
	];

	// Render
	return (
		<>
			<Box className={classes.container}>
				<TabBar
					tabs={menus}
					grow
					position="center"
					activeTab={menus.findIndex(menu => menu.path === location.pathname)}
					onTabChange={i => {
						navigate(menus[i].path);
					}}
				/>
				<Box className={classes.tabContent}>
					<Outlet />
				</Box>
			</Box>
		</>
	);
};

export default MenuSidebar;
