import {Badge as MantineBadge} from '@mantine/core';
import PropTypes from 'prop-types';

// Local styles
import {useStyles} from './styles';

const Badge = ({
	bordered,
	transform,
	size,
	color,
	className,
	...rest
}) => {
	const {classes, cx} = useStyles({
		color,
		bordered,
		textTransform: transform,
	});
	// Render
	return (
		<MantineBadge
			className={cx(classes.badge, classes[size], className)}
			{...rest}
		/>
	);
};

Badge.defaultProps = {
	size: 'md',
	color: 'primary',
};

Badge.propTypes = {
	bordered: PropTypes.bool,
	transform: PropTypes.oneOf(['uppercase', 'capitalize', 'lowercase']),
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	color: PropTypes.oneOf(['primary', 'error', 'warning', 'success', 'info', 'caution', 'neutral']),
	className: PropTypes.string,
};

export default Badge;
