export default {
	fontFamily: 'Inter, sans-serif',
	headings: {
		fontFamily: 'Inter, sans-serif',
	},
	black: '#0F192E', // Default black
	// Some colors are included with their mantine default colors as it's overriden & mantine components uses to it's 0-9 index by default
	colors: {
		light: {
			text: {
				primary: '#0F192E',
				secondary: 'rgba(15, 25, 46, 0.6)',
				tertiary: 'rgba(15, 25, 46, 0.4)',
				disabled: 'rgba(15, 25, 46, 0.2)',
			},
			neutral: {
				primary: '#FFFFFF',
				base: '#FAFAFB',
				secondary: '#F5F5F6',
				border: '#ECEDEF',
			},
			primary: {
				background: '#FFF4F6',
				border: '#F06F86',
				default: '#BC1433',
				hover: '#900B24',
				pressed: '#600516',
			},
			success: {
				background: '#F2FEF9',
				border: '#68DFA7',
				default: '#12A25F',
				hover: '#0A7D47',
				pressed: '#05532E',
			},
			warning: {
				background: '#FFFAE0',
				border: '#FFE17F',
				default: '#FAB300',
				hover: '#DD9E00',
				pressed: '#B98400',
			},
			error: {
				background: '#FFF4F6',
				border: '#FF9BAF',
				default: '#F33055',
				hover: '#D70029',
				pressed: '#B40022',
			},
			info: {
				background: '#F1FAFE',
				border: '#75CCEA',
				default: '#008BBA',
				hover: '#00759D',
				pressed: '#005A79',
			},
			caution: {
				background: '#FFF5E8',
				border: '#FFB273',
				default: '#F56200',
				hover: '#DD5900',
				pressed: '#A94400',
			},
			modal: {
				overlayBlack: 'rgba(0, 0, 0, 0.8)',
				overlayWhite: 'rgba(255, 255, 255, 0.8)',
			},
			reddish: 'linear-gradient(135deg, #DE2B4C 0%, #BC1433 64.05%)',
			skin: '#F0D9C4',
			nose: '#908376',
			clear: '#F5FAFF',
		},
		dark: {
			text: {
				primary: '#FFFFFF',
				secondary: 'rgba(255, 255, 255, 0.8)',
				tertiary: 'rgba(255, 255, 255, 0.4)',
				disabled: 'rgba(255, 255, 255, 0.2)',
			},
			neutral: {
				primary: '#000000',
				base: '#131313',
				secondary: '#1C1C1C',
				border: '#202020',
			},
		},
	},
	shadows: {
		xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
		sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
		md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
		lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
		xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
		xl2: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
		xl3: '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
		esopqu: '0px 4px 10px rgba(0, 0, 0, 0.05)',
		hangryUp: '0px -4px 10px rgba(0, 0, 0, 0.05)',
		hangryDown: '0px 1px 10px rgba(0, 0, 0, 0.05)',
		hangryLeft: '-1px 0px 10px rgba(0, 0, 0, 0.05)',
		hangryRight: '1px 0px 10px rgba(0, 0, 0, 0.05)',
	},
	breakpoints: {
		sm: 640,
		md: 768,
		lg: 1024,
		xl: 1280,
	},
};
