// Global utils
import AuthUtils from 'utils/auth';

import {store} from 'store';

// Request utils
import request, {REQUEST_ENV} from 'request';
import {setTurnOffReasons} from 'store/actions';

export const getTurnOffReasonsRequest = async setState => {
	try {
		const {data} = await request(
			{
				method: 'get',
				url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/menu-availability/reason`,
				headers: {
					Authorization: `Bearer ${AuthUtils.sessionToken}`,
				},
			},
			setState,
		);
		store.dispatch(setTurnOffReasons(data));
	} catch {}
};

export const getOrderListRequest = async (payload, setState) => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	// payload: {orderDate, locationId, status, limit, offset, sortBy, sortOrder}
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: {
				orderDate: new Date(),
				locationId,
				limit: 0,
				offset: 0,
				...payload,
			},
		},
		setState,
	);
};

export const getReconSummaryRequest = async (payload, setState) => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	// payload: {locationId, startTime, endTime}
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/summary`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: {
				locationId,
				...payload,
			},
			timeout: 60000,
		},
		setState,
	);
};
export const getReconDetailsRequest = async (payload, setState) => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	// payload: {locationId, startTime, endTime, brandId, platformId}
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/summary/list`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: {
				locationId,
				limit: 999999,
				...payload,
			},
			timeout: 60000,
		},
		setState,
	);
};

export const processOrderRequest = async (payload, setState) => {
	// payload: {orderId, processedBy}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/process`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const flagCancelOrderRequest = async (payload, setState) => {
	// payload: {orderId, reason}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/mark/cancel`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const flagUpdateOrderRequest = async (payload, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/update`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const markFraud = async (payload, setState) => {
	const {externalId} = payload;
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/mark/fraudulent/${externalId}`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
		},
		setState,
	);
};

// Hangry App APIs
export const cancelHangryAppOrderRequest = async (payload, setState) => {
	// payload: {orderId, reason}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/cancel`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const findDriverHangryAppOrderRequest = async (payload, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/find-driver`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const changeDriverHangryAppOrderRequest = async (payload, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/change-driver`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const markPickupReadyHangryAppOrderRequest = async (payload, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/process/ready`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const autoInjectOrder = async (payload, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'post',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/inject`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};