import {inputStyles} from './Inputs';

// Override default mantine's sizing style based on Untitled UI's sizing.

// Please note mantine's `size` props will not work here because it's overriden by the style below.
const sharedStyles = theme => ({
	calendarBase: {
		maxWidth: 'unset',
		gap: 0,
		justifyContent: 'center',
		'& > div' : {
			padding: '20px 24px',
			':not(:last-child)': {
				borderRight: `1px solid ${theme.colors.light.neutral.border}`,
			},
		},
	},
	calendarHeaderLevel: {
		fontSize: 16,
		lineHeight: '24px',
		fontWeight: 700,
		color: theme.colors.light.text.primary,
	},
	weekdayCell: {
		width: 40,
		height: 40,
		paddingBottom: 0,
	},
	weekday: {
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 700,
		color: theme.colors.light.text.primary,
	},
	cell: {
		borderTopWidth: 4,
	},
	day: {
		borderRadius: '50%',
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 500,
		color: theme.colors.light.text.primary,
		width: 40,
		height: 40,
		border: 0,
		'&:hover': {
			backgroundColor: `${theme.colors.light.primary.background}`,
			fontWeight: 700,
			color: theme.colors.light.primary.default,
		},
		'&:disabled': {
			color: theme.colors.light.text.disabled,
		},
	},
	weekend: {
		color: `${theme.colors.light.text.primary} !important`,
		'&:hover': {
			color: `${theme.colors.light.primary.default} !important`,
		},
		'&:disabled': {
			color: `${theme.colors.light.text.disabled} !important`,
		},
	},
	selected: {
		...firstLastRange(theme, 'selected'),
		fontWeight: 700,
	},
});

const firstLastRange = (theme, type) => {
	return {
		backgroundColor: `${theme.colors.light.primary.default} !important`,
		color: `${theme.colors.light.neutral.primary} !important`,
		'&:hover': {
			color: `${theme.colors.light.neutral.primary} !important`,
		},
		...(type !== 'selected' && {
			'&:before': {
				zIndex: -1,
				content: '""',
				position: 'absolute',
				width: '50%',
				backgroundColor: theme.colors.light.primary.background,
				top: 0,
				bottom: 0,
				...(type === 'first' ? {right: 0} : {left: 0}),
			},
		}),
	};
};

const Calendar = theme => ({
	...sharedStyles(theme),
});

const RangeCalendar = theme => ({
	...sharedStyles(theme),
	inRange: {
		backgroundColor: `${theme.colors.light.primary.background} !important`,
		fontWeight: 500,
		color: `${theme.colors.light.primary.hover} !important`,
	},
	firstInRange: {
		...firstLastRange(theme, 'first'),
	},
	lastInRange: {
		...firstLastRange(theme, 'last'),
	},
});

const DatePicker = theme => ({
	...inputStyles(theme),
	...sharedStyles(theme),
	wrapper: {
		height: 44,
	},
	dropdown: {
		padding: 0,
	},
});

export {DatePicker, Calendar, RangeCalendar};