import {useTranslation} from 'react-i18next';
import {Box, Center, Group} from '@mantine/core';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {store} from 'store';

// Global components
import Text from 'components/core/Text';

// Local styles
import {useStyles} from './styles';
import notification, {generalError} from 'utils/notification';
import Button from 'components/core/Button';
import FaDisplay from 'components/others/FaDisplay';
import {faArrowRotateRight} from '@fortawesome/pro-regular-svg-icons';
import {retryOnOffRequest} from 'request/outletOnOff';
import {socket} from 'utils/socket';

const MERCHANT_STATUS_INITIAL_STATE = {
	count: 0,
	open: 0,
	close: 0,
	pending: [],
	success: [],
	failed: [],
	retryable: [],
	cancelled: [],
};

const OutletStatus = () => {
	const navigate = useNavigate();
	const {t} = useTranslation('translation', {keyPrefix: 'global.header.merchantsStatus'});
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	const [merchantsStatus, setMerchantsStatus] = useState(MERCHANT_STATUS_INITIAL_STATE);
	const [indicatorColor, setIndicatorColor] = useState('info');
	const [indicatorStatus, setIndicatorStatus] = useState('Pending');

	const {classes, cx} = useStyles({indicatorColor});

	const handleRetry = async merchantIds => {
		try {
			await retryOnOffRequest({merchantIds});
			notification.hide('errorToggle');
		} catch (error) {
			generalError(error);
		} finally {
			socket.emit(`get_merchant_details:${locationId}`);
		}
	};

	const merchantsSocketHandler = data => {
		let isAuto = false;
		let showNotif = false;
		const tempPayload = {
			count: 0,
			open: 0,
			close: 0,
			pending: [],
			success: [],
			failed: [],
			retryable: [],
			cancelled: [],
		};

		data.forEach((merchant, index) => {
			if (merchant.lastUpdateStatus) {
				tempPayload[merchant.lastUpdateStatus].push(merchant);

				if (merchant.lastUpdateStatus === 'failed') {
					/**
					 * Check failed request
					 * if failed request is auto and turning off, no need to retry
					 * else save failed request to retryable array
					 */
					if (merchant.updateMethod === 'auto') {
						if (merchant.lastUpdateTo === true) {
							tempPayload.retryable.push(merchant);
						}
					} else {
						tempPayload.retryable.push(merchant);
					}
				}
			}

			if (merchant.merchantIsOpen) tempPayload.open++;
			else tempPayload.close++;
			tempPayload.count++;

			// Check update method
			if (index === 0 && merchant.updateMethod === 'auto') isAuto = true; // Need to recheck flow to handle auto
		});

		setMerchantsStatus(tempPayload);

		// Get elapsed time since last action, if last action is within 2 minutes, show notification
		const lastUpdateTime = data[0]?.processedAt;

		// Get the current time
		const currentTime = new Date();

		// Specify the specific time
		const specificTime = new Date(lastUpdateTime);

		// Calculate the time difference in milliseconds
		const timeDifference = currentTime - specificTime;

		// Convert milliseconds to minutes
		const elapsedMinutes = Math.floor(timeDifference / 1000 / 60);

		if (tempPayload.retryable.length) {
			// Always show notification if theres a retryable request
			showNotif = true;
		} else {
			elapsedMinutes < 2 ? showNotif = true : showNotif = false;
		}

		if (showNotif && !tempPayload.pending.length) {
			notification.hide('errorToggle');
			if (isAuto) {
				// Notification handler for auto toggle
				if (tempPayload?.success?.[0]?.lastUpdateTo === true) {
					if (tempPayload.retryable.length) {
						notification.hide('errorToggle');
						notification.error({
							id: 'errorToggle',
							autoClose: false,
							title: t('Notification.Failed.title', {
								failedQty: tempPayload.retryable.length,
							}),
							message: t('Notification.Failed.message'),
							extra: <Box mt={16}>
								<Button
									size="sm"
									leftIcon={(
										<FaDisplay
											containerSize={18}
											fontSize={14}
											faIcon={faArrowRotateRight} />
									)}
									onClick={() => handleRetry(tempPayload.retryable.map(merchant => merchant.merchantId))}
								>
									{t('Notification.Failed.action')}
								</Button>
							</Box>,
						});
					} else {
						notification.success({
							title: t('Notification.AutoToggleNotification.On.title'),
							message: t('Notification.AutoToggleNotification.On.message'),
						});
					}
				} else if (tempPayload?.success?.[0]?.lastUpdateTo === false) {
					notification.error({
						title: t('Notification.AutoToggleNotification.Off.title'),
						message: t('Notification.AutoToggleNotification.Off.message'),
						extra: <Box mt={16}>
							<Button
								size="sm"
								onClick={() => navigate('/outlet-status')}>
								{t('Notification.AutoToggleNotification.Off.action')}
							</Button>
						</Box>,
					});
				}
			} else {
				if (tempPayload.success.length || tempPayload.retryable.length) {
					if (tempPayload.retryable.length) {
						notification.hide('errorToggle');
						notification.error({
							id: 'errorToggle',
							autoClose: false,
							title: t('Notification.Failed.title', {
								failedQty: tempPayload.retryable.length,
							}),
							message: t('Notification.Failed.message'),
							extra: <Box mt={16}>
								<Button
									size="sm"
									leftIcon={(
										<FaDisplay
											containerSize={18}
											fontSize={14}
											faIcon={faArrowRotateRight} />
									)}
									onClick={() => handleRetry(tempPayload.retryable.map(merchant => merchant.merchantId))}
								>
									{t('Notification.Failed.action')}
								</Button>
							</Box>,
						});

					} else if (tempPayload.success.length) {
						notification.success({
							autoClose: 3000,
							title: t('Notification.Success.title'),
							message: t('Notification.Success.message'),
						});
					}
				}
			}
		}
	};

	useEffect(() => {
		socket.emit(`get_merchant_details:${locationId}`);
		socket.on(`merchant_details:${locationId}`, merchantsSocketHandler);

		return () => {
			socket.off();
		};
	}, [locationId]);

	useEffect(() => {
		if (merchantsStatus === MERCHANT_STATUS_INITIAL_STATE) return;
		const {pending, count, close} = merchantsStatus;
		if (pending.length) {
			// Check for pending process
			setIndicatorStatus(t('somePending'));
			setIndicatorColor('info');
		} else {
			// Check if all merchants is open
			if (close == 0) {
				setIndicatorStatus(t('allOpen'));
				setIndicatorColor('success');
			} else {
				// Check if all merchants is close
				if (count === close) {
					setIndicatorStatus(t('allClose'));
					setIndicatorColor('error');
				} else {
					// Somer merchants are close
					setIndicatorStatus(t('someClose', {
						closeCount: close,
					}));
					setIndicatorColor('warning');
				}
			}
		}
	}, [merchantsStatus]);

	return (
		<>
			<Box className={classes.separator} />
			<Group
				noWrap
				spacing={4}>
				<Center className={cx(classes.indicatorBullet)}>
					<span />
				</Center>
				<Text
					typography="title-bold-16"
					className={cx(classes.statusText)}>
					{indicatorStatus}
				</Text>
			</Group>
		</>
	);
};

export default OutletStatus;