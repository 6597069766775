import {Center} from '@mantine/core';
import PropTypes from 'prop-types';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

// Local styles
import {useStyles} from './styles';

const SIZE_MAPPING = {
	xs: {
		size: 24,
		icon: 12,
		border: 2,
	},
	sm: {
		size: 32,
		icon: 16,
		border: 4,
	},
	md: {
		size: 40,
		icon: 18,
		border: 6,
	},
	lg: {
		size: 48,
		icon: 20,
		border: 8,
	},
	xl: {
		size: 56,
		icon: 24,
		border: 10,
	},
};

const FeaturedIcon = ({
	faIcon,
	size,
	iconSize,
	borderSize,
	type,
	color,
	className,
	isSpin,
	...rest
}) => {
	const mappedSize = SIZE_MAPPING[size];
	const {classes, cx} = useStyles({
		color,
		size: typeof size === 'string' ?  mappedSize.size || 32 : size, // Prioritize mapped size
		iconSize: iconSize || mappedSize.icon || 16, // use props defined size if provided, otherwise use the mapping size
		borderSize: borderSize || mappedSize.border || 4, // use props defined size if provided, otherwise use the mapping size
	});
	// Render
	return (
		<Center
			className={cx(classes.container, className)}
			{...rest}
		>
			<Center className={cx(classes.innerContainer, classes[type])}>
				<FontAwesomeIcon
					icon={faIcon}
					className={isSpin && classes.spin} />
			</Center>
		</Center>
	);
};

FeaturedIcon.defaultProps = {
	size: 'sm',
	color: 'info',
	type: 'normal',
};

FeaturedIcon.propTypes = {
	faIcon: PropTypes.object,
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
	color: PropTypes.oneOf(['primary', 'success', 'warning', 'error', 'info', 'caution', 'neutral']),
	type: PropTypes.oneOf(['normal', 'lightCircle', 'darkCircle', 'lightSquare', 'midSquare', 'darkSquare']),
	isSpin: PropTypes.bool,
	iconSize: PropTypes.number,
	borderSize: PropTypes.number,
	className: PropTypes.string,
};

export default FeaturedIcon;
