export const inputStyles = (theme, inputPadding) => ({
	// Override default mantine's sizing style based on Untitled UI's sizing.
	// To change font size, please use the `size` prop.
	// Please note mantine's `size` props will not alter input's height because it's overriden by the style below.
	// Also when you use rightSection, please manually set the input's right padding using important style
	input: {
		textOverflow: 'ellipsis',
		minHeight: 'unset',
		height: '100%',
		borderRadius: 8,
		color: theme.colors.light.text.primary,
		borderColor: theme.colors.light.neutral.border,
		transition: '250ms',
		'&::placeholder': {
			color: theme.colors.light.text.disabled,
		},
		'&:focus': {
			borderColor: theme.colors.light.primary.border,
			boxShadow: `${theme.shadows.xs}, 0px 0px 0px 4px ${theme.colors.light.primary.background}`,
		},
		'&:disabled': {
			opacity: 1,
			backgroundColor: theme.colors.light.neutral.base,
			color: theme.colors.light.text.tertiary,
			'&::placeholder': {
				color: theme.colors.light.text.disabled,
			},
		},
		padding: inputPadding || '0 12px',
	},
	icon: {
		width: 24,
		marginLeft: 12,
		fontSize: 16,
		color: theme.colors.light.text.primary,
	},
	withIcon: {
		paddingLeft: 44,
	},
	label: {
		fontSize: 12,
		lineHeight: '18px',
		fontWeight: 500,
		color: theme.colors.light.text.secondary,
		marginBottom: 4,
	},
	invalid: {
		borderColor: theme.colors.light.error.border,
		color: theme.colors.light.text.primary,
		'&::placeholder': {
			color: theme.colors.light.text.disabled,
		},
	},
	error: {
		marginTop: 6,
		color: theme.colors.light.error.default,
		fontSize: 14,
		lineHeight: '20px',
	},
});

const NumberInput = theme => ({
	...inputStyles(theme),
	wrapper: {
		height: 44,
	},
});

const Textarea = theme => ({
	...inputStyles(theme),
});

const TextInput = theme => ({
	...inputStyles(theme),
	wrapper: {
		height: 44,
	},
});

const Select = theme => ({
	...inputStyles(theme, '0 30px 0 14px'),
	wrapper: {
		height: 44,
	},
	dropdown: {
		padding: 0,
		borderRadius: 8,
		boxShadow: theme.shadows.lg,
	},
	item: {
		borderRadius: 0,
		padding: '10px 14px',
	},
	hovered: {
		backgroundColor: theme.colors.light.neutral.secondary,
	},
	selected: {
		backgroundColor: theme.colors.light.primary.background,
		color: 'unset',
		'&:hover': {
			backgroundColor: theme.colors.light.neutral.secondary,
		},
	},
});

export {NumberInput, Textarea, TextInput, Select};