// Local Redux
import {SET_ELECTRON_DATA, CLEAR_ELECTRON_DATA} from '../actionTypes';

const initialState = {
	version: null,
	printerEnabled: false,
};

const reducerElectron = (state = initialState, {type, data}) => {
	switch (type) {
		case SET_ELECTRON_DATA:
	  return {...state, ...data};
		case CLEAR_ELECTRON_DATA:
	  return initialState;
		default:
			return state;
	}
};

export default reducerElectron;
