import {combineReducers} from 'redux';

import reducerOrder from './reducerOrder';
import reducerUser from './reducerUser';
import reducerRemoteConfig from './reducerRemoteConfig';
import reducerMenu from './reducerMenu';
import reducerElectron from './reducerElectron';
import reducerOutlet from './reducerOutlet';

export default combineReducers({
	reducerOrder,
	reducerUser,
	reducerRemoteConfig,
	reducerMenu,
	reducerOutlet,
	reducerElectron,
});
