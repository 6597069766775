import ReactGA from 'react-ga4';

import {store} from 'store';

import packageJson from '../../package.json';

class Analytics {
	constructor() {
		this.defaultProperties = {
			appVersion: packageJson.version,
			timestamp: `${new Date().getTime()}`,
		};

		this.ReactGA = null;

		this.buildTrack = process.env.REACT_APP_RELEASE_STAGE;
		this.analyticsEnabled = process.env.NODE_ENV !== 'test' && this.buildTrack === 'production';
	}

	initialize(gaIdCustom) {
		if (this.analyticsEnabled) {
			const gaId = gaIdCustom || process.env.REACT_APP_GA4_ID;
			if (gaId) {
				ReactGA.initialize(gaId);
				this.ReactGA = ReactGA;
			}
			this.setUserProperties();
			this.trackPerformance();
		}
	}

	setUserProperties() {
		if (this.analyticsEnabled) {
			const {id, username, selectedLocation} = store.getState()?.reducerUser || {};
			const {version} = store.getState()?.reducerElectron || {};
			this.defaultProperties.id = id;
			this.defaultProperties.username = username;
			this.defaultProperties.outletId = selectedLocation?.id;
			this.defaultProperties.outletName = selectedLocation?.label;
			this.defaultProperties.electronVersion = version || 'Not Electron';
		}
	}

	logEvent(eventName, param = {}) {
		if (this.ReactGA && this.analyticsEnabled) {
			try {
				const eventParam = {...this.defaultProperties, ...param};
				this.ReactGA.event(eventName, eventParam);
			} catch (e) {
				console.log(e);
			}
		}
	}

	pageview() {
		if (this.ReactGA && this.analyticsEnabled) {
			this.ReactGA.send('pageview');
		}
	}

	trackPerformance() {
		try {
			const observer = new PerformanceObserver(list => {
				for (const entry of list.getEntries()) {
					this.logEvent('paint_performance', {
					  timingCategory: 'Paint metrics',
					  timingVar: entry.name,
					  timingValue: Math.round(entry.startTime),
					});
				}
			});
			observer.observe({
				entryTypes: ['paint'],
			});
		} catch (e) {
			// Do nothing if the browser doesn't support this API
		}
	}

}

const analytics = new Analytics();

export default analytics;

