import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';

// Global utils
import analytics from 'utils/analytics';

// Request utils
import {authCheckRequest, logoutUserRequest} from 'request/auth';

// Redux
import {store} from 'store';
import {clearUserData} from 'store/actions';
import {sendAuthTokenToElectron} from './electron';

class AuthUtil {
	constructor() {
		this.sessionKey = 'SES';
	}
	get sessionStorageKey() {
		return this.sessionKey;
	}
	get sessionToken() {
		return Cookies.get(this.sessionKey);
	}
	async isSessionValid() {
		analytics.setUserProperties();
		if (!isEmpty(this.sessionToken)) {
			const result = await authCheckRequest();
			sendAuthTokenToElectron(this.sessionToken);
			return result;
		} else {
			sendAuthTokenToElectron(false);
			return Promise.reject(new Error('TOKEN_DONT_EXIST'));
		}
	}
	revokeCurrentSession(setState) {
		return new Promise((resolve, reject) => {
			logoutUserRequest(setState)
				.then(resolve)
				.catch(reject)
				.finally(() => {
					Cookies.remove(this.sessionKey);
					store.dispatch(clearUserData());
				});
		});
	}
	async setNewSession(token) {
		return await Cookies.set(this.sessionKey, token, {
			expires: 30,
		});
	}
}

export default new AuthUtil();
