import {createStyles} from '@mantine/core';

export const useStyles = createStyles(() => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
	tabContent: {
		flex: 1,
		padding: '24px max(calc((100% - 912px) / 2), 16px)',
		overflowY: 'auto',
		position: 'relative',
	},
}));