import {useRef, useEffect} from 'react';
import crashLogger from 'utils/logger';

const useBellSound = (file, autoStopOnFocus = true) => {
	const Bell = new Audio(file);
	const loopRef = useRef();

	const start = async (loop = false, loopInterval = 2500) => {
		try {
			await Bell.play();
			if (loop) {
				loopRef.current = setTimeout(() => {
					start(loop, loopInterval);
				}, loopInterval);
			}
		} catch (error) {
			if (error?.message === 'NotAllowedError') {
				crashLogger.error('Sound notification is not set to always allow', 'error');
			}
			console.error(error);
		}
	};

	const stop = () => {
		Bell.pause();
		clearTimeout(loopRef.current);
	};

	const play = (loop, loopInterval) => {
		stop(); // reset states before playing

		start(loop, loopInterval);
	};

	const handleInteraction = () => {
		stop();
	};

	useEffect(() => {
		if (autoStopOnFocus) {
			document.addEventListener('mousedown', handleInteraction);
			document.addEventListener('touchstart', handleInteraction);
			return () => {
				document.removeEventListener('mousedown', handleInteraction);
				document.removeEventListener('touchstart', handleInteraction);
				stop();
			};
		}
	}, []);

	return [play, stop];
};

export default useBellSound;
