import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';

// Translation JSON Data
import enJson from 'assets/data/i18n-en.json';
import idJson from 'assets/data/i18n-id.json';

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: enJson,
		},
		id: {
			translation: idJson,
		},
	},
	lng: Cookies.get('i18n') || 'id',
	fallbackLng: 'id',
	interpolation: {
		escapeValue: false,
	},
});

export const setupDayjs = () => {
	dayjs.locale(Cookies.get('i18n') || 'id'); // Set dayjs locale
	dayjs.updateLocale('id', {
		monthsShort: [
			'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun',
			'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des',
		],
		relativeTime: {
			future: 'dalam %s',
			past: '%s lalu',
			s: '1 menit',
			m: '1 menit',
			mm: '%d menit',
			h: '1 jam',
			hh: '%d jam',
			d: '1 hari',
			dd: '%d hari',
			M: '1 bulan',
			MM: '%d bulan',
			y: '1 tahun',
			yy: '%d tahun',
		},
	});
};