import {Text as MantineText} from '@mantine/core';
import PropTypes from 'prop-types';

const TYPOGRAPHIES = {
	// Heading
	'heading-h1-64': {
		fontSize: 64,
		lineHeight: '80px',
		fontWeight: 800,
	},
	'heading-h2-48': {
		fontSize: 48,
		lineHeight: '60px',
		fontWeight: 800,
	},
	'heading-h3-32': {
		fontSize: 32,
		lineHeight: '40px',
		fontWeight: 800,
	},
	'heading-h4-24': {
		fontSize: 24,
		lineHeight: '32px',
		fontWeight: 700,
	},
	'heading-h5-20': {
		fontSize: 20,
		lineHeight: '28px',
		fontWeight: 700,
	},
	'heading-h6-18': {
		fontSize: 18,
		lineHeight: '26px',
		fontWeight: 700,
	},

	// Title
	'title-bold-20': {
		fontSize: 20,
		lineHeight: '28px',
		fontWeight: 700,
	},
	'title-bold-18': {
		fontSize: 18,
		lineHeight: '26px',
		fontWeight: 700,
	},
	'title-bold-16': {
		fontSize: 16,
		lineHeight: '24px',
		fontWeight: 700,
	},
	'title-bold-14': {
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 700,
	},

	// Subtitle
	'subtitle-medium-20': {
		fontSize: 20,
		lineHeight: '28px',
		fontWeight: 500,
	},
	'subtitle-medium-18': {
		fontSize: 18,
		lineHeight: '26px',
		fontWeight: 500,
	},
	'subtitle-medium-16': {
		fontSize: 16,
		lineHeight: '24px',
		fontWeight: 500,
	},
	'subtitle-medium-14': {
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 500,
	},

	// Body
	'body-regular-20': {
		fontSize: 20,
		lineHeight: '28px',
		fontWeight: 400,
	},
	'body-regular-18': {
		fontSize: 18,
		lineHeight: '26px',
		fontWeight: 400,
	},
	'body-regular-16': {
		fontSize: 16,
		lineHeight: '24px',
		fontWeight: 400,
	},
	'body-regular-14': {
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 400,
	},

	// Caption
	'caption-bold-12': {
		fontSize: 12,
		lineHeight: '18px',
		fontWeight: 700,
	},
	'caption-medium-12': {
		fontSize: 12,
		lineHeight: '18px',
		fontWeight: 500,
	},
	'caption-regular-12': {
		fontSize: 12,
		lineHeight: '18px',
		fontWeight: 400,
	},

	// Footer
	'footer-bold-10': {
		fontSize: 10,
		lineHeight: '12px',
		fontWeight: 700,
	},
	'footer-medium-10': {
		fontSize: 10,
		lineHeight: '12px',
		fontWeight: 500,
	},

	// Label
	'label-bold-20': {
		fontSize: 20,
		lineHeight: '28px',
		fontWeight: 700,
		letterSpacing: 1.25,
		textTransform: 'uppercase',
	},
	'label-bold-12': {
		fontSize: 12,
		lineHeight: '18px',
		fontWeight: 700,
		letterSpacing: 1.25,
		textTransform: 'uppercase',
	},

	// Promo
	'promo-medium-12': {
		fontSize: 12,
		lineHeight: '18px',
		fontWeight: 500,
		textDecorationLine: 'line-through',
	},
};

const Text = ({typography, color, style, ...rest}) => {
	// Render
	return (
		<MantineText
			style={{
				color,
				...(TYPOGRAPHIES[typography] ? TYPOGRAPHIES[typography] : {}),
				...style,
			}}
			{...rest}
		/>
	);
};

Text.defaultProps = {
	style: {},
};

Text.propTypes = {
	typography: PropTypes.oneOf([
		...Object.keys(TYPOGRAPHIES),
	]),
	color: PropTypes.string,
	style: PropTypes.object,

	// Mantine core props
	align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),

	// Mantine text only props
	component: PropTypes.string,
	lineClamp: PropTypes.number,
	transform: PropTypes.oneOf(['none', 'capitalize', 'uppercase', 'lowercase']),
	underline: PropTypes.bool,
};

export default Text;
