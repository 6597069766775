export const TIME_LIMIT = 300; //in seconds

export const ERROR_CODES = {
	AUTH_INVALID: 'auth.invalid',
	AUTH_LOCKED: 'auth.locked',
	AUTH_INACTIVE: 'auth.inactive',
};

export const SUPPORTED_ORDER_PLATFORMS = {
	GRAB: 'Grab',
	GOJEK: 'Gojek',
	SHOPEE: 'Shopee',
	HANGRY_APP: 'Hangry App',
};

export const SUPPORTED_MENU_PLATFORMS = {
	GRAB: 'Grab',
	GOJEK: 'Gojek',
	SHOPEEE: 'Shopee',
	HANGRY_APP: 'Hangry App',
};

export const MERCHANT_APP_NAMES = {
	'Grab': 'Grab Merchant',
	'Gojek': 'GoBiz',
	'Shopee': 'Shopee Partner',
};

export const ORDER_STATUS = {
	// Pending & Pricessing
	ACCEPTED: 'accepted',
	DRIVER_ARRIVED: 'driverArrived',
	DRIVER_FOUND: 'driverFound',
	// History
	CANCELLED: 'cancelled',
	COLLECTED: 'collected',
	DELIVERED: 'delivered',
	FAILED: 'failed',
};

export const PROBLEMATIC_CODES = {
	DEFAULT_ERROR: 10,
	PRICE_MISMATCH: 11,
	ROBOT_OFFLINE: 12,
	POS_OFFLINE: 13,
	MENU_ERROR: 14,
};

export const REQUEST_STATUS = {
	PENDING: 'pending',
	FAILED: 'failed',
	CANCELLED: 'cancelled',
	SUCCESS: 'success',
	PROCESSED: 'processed',
};

export const MANAGE_BRAND = {
	MANAGE_ON: 'manage_on',
	MANAGE_OFF: 'manage_off',
};