import theme from './mantineTheme';

export default {
	Loader: {
		color: theme.colors.light.primary.default,
	},
	LoadingOverlay: {
		overlayColor: 'transparent',
		zIndex: 10,
	},
	Anchor: {
		underline: false,
		weight: 500,
		style: {
			color: theme.colors.light.primary.default,
		},
	},
	Menu: {
		gutter: 10,
		shadow: theme.shadows.lg,
		radius: 8,
	},
	Checkbox: {
		size: 'xs',
	},
	CheckboxGroup: {
		size: 'xs',
	},
	Modal: {
		trapFocus: false,
	},
	Drawer: {
		trapFocus: false,
	},
	Calendar: {
		weekdayLabelFormat: 'ddd', // For some reasons, DatePicker weekdayLabelFormat are not working, thus it's set here
	},
};
