// Global utils
import AuthUtils from 'utils/auth';

import {store} from 'store';

// Request utils
import request, {REQUEST_ENV} from 'request';

export const getLocationsRequest = async setState => {
	return request({
		method: 'get',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/cms/location`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
		params: {
			offset: 0,
			limit: 0,
		},
	}, setState);
};

export const getBrandsRequest = async setState => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request({
		method: 'get',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/cms/brand-by-location`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
		params: {
			locationId,
		},
	}, setState);
};

export const getPlatformsRequest = async setState => {
	return request({
		method: 'get',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/cms/platform`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
	}, setState);
};

export const getOutletAutoInjectEnabled = async setState => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request({
		method: 'get',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/cms/outlet`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
		params: {
			offset: 0,
			limit: 0,
			locationId,
			autoInjectFlag: true,
			activationStatus: 'Success',
		},
	}, setState);
};