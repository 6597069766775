import {Anchor, Box, Group, Image, Menu, Stack} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {Link, Outlet as RouterOutlet, useLocation, useNavigate} from 'react-router-dom';

// Fontawesome
import {faBars, faBookArrowRight, faChevronLeft, faFileInvoice} from '@fortawesome/pro-solid-svg-icons';

// Global assets
import SVGLogo from 'assets/logos/Fresto.svg';

// Global components
import Text from 'components/core/Text';
import Button from 'components/core/Button';
import FaDisplay from 'components/others/FaDisplay';

// Local components
import Outlet from './Outlet';
import OutletStatus from './OutletStatus';
import ServiceStatus from './ServiceStatus';

// Local styles
import {useStyles} from './styles';

const Header = () => {
	const {t} = useTranslation('translation', {keyPrefix: 'global.header'});
	const {classes, theme} = useStyles();
	const location = useLocation();
	const navigate = useNavigate();

	const getButtonsConfig = () => {
		const frestoMenus = [
			{icon: faFileInvoice, label: t('reconciliation'), onClick: () => navigate('/recon'), exludeIn: '/recon'},
			{icon: faBookArrowRight, label: t('manageMenu'), onClick: () => navigate('/menu'), excludeIn: '/menu'},
			{icon: faChevronLeft, label: t('manageOrder'), onClick: () => navigate('/'), excludeIn: '/'},
		];

		return frestoMenus.filter(menu => menu.excludeIn !== location.pathname);
	};

	const availableMenus = getButtonsConfig();

	// Render
	return (
		<>
			<Group
				className={classes.header}
				spacing={16}>
				<Group
					noWrap
					className={classes.column}
					spacing={16}>
					<Anchor
						component={Link}
						to="/">
						<Image
							src={SVGLogo}
							width={76}
							height={32}
							fit="contain" />
					</Anchor>
					<ServiceStatus />
				</Group>
				<Box className={classes.column}>
					<Outlet />
					<OutletStatus />
				</Box>
				<Group className={classes.column}>
					<Menu
						control={(
							<Stack>
								<Button
									type='button'
									size='sm'>
									<Group
										noWrap
										spacing={4}>
										<FaDisplay
											containerSize={18}
											faIcon={faBars}
											fontSize={14}
										/>
										<Box>
											{t('frestoFeature')}
										</Box>
									</Group>
								</Button>
							</Stack>
						)}
					>
						<Stack
							p={10}
							spacing={0}>
							<Menu.Label
								p={0}
								pb={6}>
								<Text
									typography="subtitle-medium-16"
									color={theme.colors.light.text.secondary}>
									{t('chooseFeature')}
								</Text>
							</Menu.Label>
							{availableMenus.map(({label, icon, onClick}, i) => (
								<Menu.Item
									mt={i === 0 ? 0 : 4}
									p="2px 4px"
									onClick={() => onClick()}
									key={i}>
									<Text
										typography="title-bold-16"
										color={theme.colors.light.text.primary}>
										<Group>
											<FaDisplay
												containerSize={18}
												faIcon={icon}
												fontSize={14}
											/>
											{label}
										</Group>
									</Text>
								</Menu.Item>
							))}
						</Stack>
					</Menu>
				</Group>
			</Group>
			<RouterOutlet />
		</>
	);
};

export default Header;
