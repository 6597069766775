import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	indicatorBullet: {
		width: 14,
		height: 14,
		borderRadius: '50%',
		backgroundColor: theme.colors.light.error.background,
		'span': {
			width: 8,
			height: 8,
			borderRadius: '50%',
			backgroundColor: theme.colors.light.error.default,
		},
	},
	indicatorBulletActive: {
		backgroundColor: theme.colors.light.success.background,
		'span': {
			backgroundColor: theme.colors.light.success.default,
		},
	},
	statusText: {
		textTransform: 'capitalize',
		color: theme.colors.light.error.default,
		whiteSpace: 'nowrap',
	},
	statusTextActive: {
		color: theme.colors.light.success.default,
	},
}));