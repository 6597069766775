import {Box} from '@mantine/core';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

// Redux
import {store} from 'store';
import {setActiveOrderData, setBrands, setHistoryOrderData, setIsFetchingActiveOrder, setIsFetchingHistoryOrder, setIsServerOnline, setOrderDrawerData} from 'store/actions';

// Fontawesome
import {faVolumeSlash} from '@fortawesome/pro-solid-svg-icons';
import {faArrowsRotate} from '@fortawesome/pro-regular-svg-icons';

// Global components
import Button from 'components/core/Button';
import FaDisplay from 'components/others/FaDisplay';

// Global utils
import AuthUtils from 'utils/auth';
import {socket} from 'utils/socket';
import notification from 'utils/notification';

// Request Utils
import {getBrandsRequest, getOutletAutoInjectEnabled} from 'request/cms';
import {autoInjectOrder, getOrderListRequest, getTurnOffReasonsRequest} from 'request/resto';
import {getOutletOnOffReasonAndDuration} from 'request/outletOnOff';

// Lifecycle Hooks
import useBellSound from 'hooks/useBellSound';

// Global assets
import AlarmMp3 from 'assets/sounds/alarm.mp3';

// Flow:
// 1. On new order, BE will try to inject the order to POS.
// 2. FE will fetch order List.
//    Pending tabs will list orders that are failed to injecty by BE.
//    Diproses tabs will list injected or manually processed orders.
//    Selesai tabs will list orders that are completed
// 3. Ring alarm when there are pending orders
// 4. Alarm will not be fired when snooze is active (when user turn off with alarm toast or they interact with pending page)

const TURN_OFF_ALARM_SNOOZE_DURATION = 3 * 60 * 1000; // 3 minutes

const OrderListener = () => {
	const fetchTimeout = useRef();
	const {t} = useTranslation('translation', {keyPrefix: 'global.orderListener'});
	const id = useSelector(({reducerUser}) => reducerUser?.selectedLocation?.id);
	const [playOrderAlarm, stopOrderAlarm] = useBellSound(AlarmMp3, false);

	const clearFetchTimeout = () => clearTimeout(fetchTimeout.current);

	const fetchOrderErrorNotification = () => {
		notification.error({
			id: 'orderFetchError',
			title: t('fetchOrderErrorToast'),
		 	message: t('fetchOrderErrorToastSubtitle'),
			extra: <Box mt={16}>
				<Button
					size="sm"
					leftIcon={(
						<FaDisplay
							containerSize={18}
							fontSize={14}
							faIcon={faArrowsRotate} />
					)}
					onClick={() => {
						notification.hide('orderFetchError');
						clearFetchTimeout();
						fetchOrders();
					}}>
					{t('retryFetchOrder')}
				</Button>
			</Box>,
		});
	};

	// Pending order alarm methods
	const triggerAlarm = () => {
		playOrderAlarm(true, 3000);
		notification.error({
			id: 'orderAlarm',
			autoClose: false,
			title: t('injectOrderErrorToast'),
			message: t('injectOrderErrorToastSubtitle'),
			extra: <Box mt={16}>
				<Button
					size="sm"
					leftIcon={(
						<FaDisplay
							containerSize={18}
							fontSize={14}
							faIcon={faVolumeSlash} />
					)}
					onClick={() => {
						notification.hide('orderAlarm');
						stopOrderAlarm();
						window.snoozeAlarmUntil = Date.now() + TURN_OFF_ALARM_SNOOZE_DURATION;
					}}>
					{t('turnOffAlarm')}
				</Button>
			</Box>,
		});
	};
	const checkPendingOrdersForAlarm = orders => {
		const havePendingOrders = orders.some(e => {
			const elapsedTime = (Date.now() - new Date(e.createdAt).getTime()) / 1000;
			const isLoading = elapsedTime < 30 || e.isInjecting; // order is marked as loading created less than 30 seconds
			return !e.isProcessed && !isLoading;
		});
		const isAlarmSnoozed = Date.now() < window.snoozeAlarmUntil;
		if (window.allowPlayingAlarm && havePendingOrders) {
			if (!isAlarmSnoozed) {
				triggerAlarm();
			}
		} else {
			stopOrderAlarm();
		}
	};

	const injectIndividualOrder = async data => {
		try {
			await autoInjectOrder({orderId: data.id});
		} catch (error) {
			notification.generalError(error);
		}
	};

	const fetchOrders = async (skipPendingAlarm = false) => {
		try {
			clearFetchTimeout();
			store.dispatch(setIsFetchingActiveOrder(true));
			await checkServerStatus();
			const {data} = await getOrderListRequest({ // Only fetch pending and processing status
				sortBy: 'createdAt',
				sortOrder: 'asc',
				status: 'pending',
			});
			store.dispatch(setActiveOrderData(data));
			store.dispatch(setIsFetchingActiveOrder(false));
			!skipPendingAlarm && checkPendingOrdersForAlarm(data);
			fetchHistoryOrders();
			updateDrawerData(data);
		} catch (error) {
			if (error.message === 'autoInjectFailed') {
				// refresh order after injecting orders failed
				return setTimeout(() => fetchOrders(), 100);
			}
			fetchOrderErrorNotification();
		} finally {
			store.dispatch(setIsFetchingActiveOrder(false));
			fetchTimeout.current = setTimeout(fetchOrders, 30 * 1000); // Polling every 30 seconds
		}
	};

	const fetchHistoryOrders = async () => {
		try {
			const orderDate = store.getState().reducerOrder.historyDate;
			store.dispatch(setIsFetchingHistoryOrder(true));
			const {data} = await getOrderListRequest({ // Only fetch pending and processing status
				orderDate,
				sortBy: 'createdAt',
				sortOrder: 'desc',
				status: 'done',
			});
			store.dispatch(setHistoryOrderData(data));
			updateDrawerData(data);
		} catch (error) {
		} finally {
			store.dispatch(setIsFetchingHistoryOrder(false));
		}
	};

	const updateDrawerData = newOrderData => {
		const drawerData = store.getState().reducerOrder.drawer;
		if (!drawerData.open) return; // Abort checking when drawer is not open
		const foundOrder = newOrderData.find(order => order?.id === drawerData?.data?.id);
		if (foundOrder) {
			store.dispatch(setOrderDrawerData({...drawerData, data: foundOrder}));
		}
	};

	const checkServerStatus = async () => {
		try {
			const isEnabled = await checkAutoInjectEnabled();
			store.dispatch(setIsServerOnline(isEnabled));
			window.allowPlayingAlarm = isEnabled;
			return true;
		} catch {
			store.dispatch(setIsServerOnline(false));
			return false;
		}
	};

	const checkAutoInjectEnabled = async () => {
		try {
			let enabled = window.autoInjectEnabled;
			if (window.autoInjectEnabled === undefined) {
				const {data} = await getOutletAutoInjectEnabled();
				enabled = !!data?.rows?.length;
				window.autoInjectEnabled = enabled;
			}
			return enabled;
		} catch (e) {
			console.log(e);
			return false;
		}
	};

	const fetchPlatformAndBrands = async () => {
		try {
			const {data} = await getBrandsRequest();
			store.dispatch(setBrands(data.sort((a, b) => a.label.localeCompare(b.label))));
		} catch {} // On error do nothing
	};

	// Lifecycle Hooks
	useEffect(() => {
		window.refreshOrders = fetchOrders;
		window.refreshHistoryOrders = fetchHistoryOrders;
		window.stopOrderPolling = clearFetchTimeout;
		window.injectIndividualOrder = injectIndividualOrder;
		window.checkServerStatus = checkServerStatus;
		window.stopOrderAlarm = () => {
			notification.hide('orderAlarm');
			stopOrderAlarm();
			window.snoozeAlarmUntil = Date.now() + TURN_OFF_ALARM_SNOOZE_DURATION;
		};
		if (id) {
			AuthUtils.isSessionValid().then(async () => {
				fetchOrders();
				fetchPlatformAndBrands();
				getTurnOffReasonsRequest();
				getOutletOnOffReasonAndDuration();
			}).catch(() => {}); // Do nothing when session is invalid

			socket.on(`inject_failed:${id}`, () => {
				window.snoozeAlarmUntil === undefined; // Reset snooze to display notification whenever auto process failed
				fetchOrders();
			});
		}
		return () => {
			clearTimeout(fetchTimeout.current);
			socket.off();
		};
	}, [id]);

	// Render nothing
	return null;
};

export default OrderListener;
