import {
	SET_USER_DATA,
	CLEAR_USER_DATA,
	SET_IS_SERVER_ONLINE,
	SET_ACTIVE_ORDERS,
	SET_IS_FETCHING_ACTIVE_ORDER,
	SET_HISTORY_DATE,
	SET_HISTORY_ORDERS,
	SET_IS_FETCHING_HISTORY_ORDER,
	SET_ORDER_DRAWER_DATA,
	CLEAR_ORDER_DRAWER_DATA,
	SET_PLATFORMS,
	SET_BRANDS,
	SET_REMOTE_CONFIG,
	SET_TURN_OFF_REASONS,
	SET_OUTLET_ON_OFF_REASONS_DURATION,
	SET_ELECTRON_DATA,
	CLEAR_ELECTRON_DATA,
} from './actionTypes';

export const setUserData = payload => {
	return {
		type: SET_USER_DATA,
		data: payload,
	};
};

export const clearUserData = () => {
	return {
		type: CLEAR_USER_DATA,
	};
};

export const setIsServerOnline = payload => {
	return {
		type: SET_IS_SERVER_ONLINE,
		data: payload,
	};
};

export const setActiveOrderData = payload => {
	return {
		type: SET_ACTIVE_ORDERS,
		data: payload,
	};
};

export const setIsFetchingActiveOrder = payload => {
	return {
		type: SET_IS_FETCHING_ACTIVE_ORDER,
		data: payload,
	};
};

export const setHistoryDate = payload => {
	return {
		type: SET_HISTORY_DATE,
		data: payload,
	};
};

export const setHistoryOrderData = payload => {
	return {
		type: SET_HISTORY_ORDERS,
		data: payload,
	};
};

export const setIsFetchingHistoryOrder = payload => {
	return {
		type: SET_IS_FETCHING_HISTORY_ORDER,
		data: payload,
	};
};

export const setOrderDrawerData = payload => {
	return {
		type: SET_ORDER_DRAWER_DATA,
		data: payload,
	};
};

export const clearOrderDrawerData = () => {
	return {
		type: CLEAR_ORDER_DRAWER_DATA,
	};
};

export const setPlatforms = payload => {
	return {
		type: SET_PLATFORMS,
		data: payload,
	};
};

export const setBrands = payload => {
	return {
		type: SET_BRANDS,
		data: payload,
	};
};

export const setRemoteConfig = payload => {
	return {
		type: SET_REMOTE_CONFIG,
		data: payload,
	};
};

export const setTurnOffReasons = payload => {
	return {
		type: SET_TURN_OFF_REASONS,
		data: payload,
	};
};

export const setOnOffOutlerReasonDuration = payload => {
	return {
		type: SET_OUTLET_ON_OFF_REASONS_DURATION,
		data: payload,
	};
};

export const setElectronData = payload => {
	return {
		type: SET_ELECTRON_DATA,
		data: payload,
	};
};

export const clearElectronData = () => {
	return {
		type: CLEAR_ELECTRON_DATA,
	};
};