import isArray from 'lodash/isArray';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
const TEST_LABEL = '[TEST]';

dayjs.extend(utc);
dayjs.extend(timezone);

export const passingProps = (props, whitelistProps, blacklistProps) => {
	return Object.keys(props).reduce((previousValue, currentValue) => {
		if (isArray(whitelistProps) && whitelistProps.includes(currentValue)) {
			return {...previousValue, [currentValue]: props[currentValue]};
		} else if (
			isArray(blacklistProps) &&
      !blacklistProps.includes(currentValue)
		) {
			return {...previousValue, [currentValue]: props[currentValue]};
		}
		return previousValue;
	}, {});
};

export const roundNumber = (number, decimals = 2) => {
	const parsedNumber = parseFloat(number);
	if (!isNaN(parsedNumber)) {
		return Math.round((parsedNumber + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
	} else {
		return number;
	}
};

export const numberFormat = amount => {
	const rounded = roundNumber(amount, 2);
	return rounded
		? rounded.toString()
			.replace(/\./g, ',') // Replace decimal point with comma
			.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
		: 0;
};

export const idrPrefix = amount => {
	const numberFormatted = numberFormat(amount);
	return 'Rp' + numberFormatted;
};

export const isEmailValid = (value = '') => {
	const regexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return value.match(regexPattern);
};

export const cleanPathname = (pathname = '') => {
	return pathname.replace(/\/$/, '');
};

export const humanFileSize = (size = 0) => {
	if (size === 0) {
		return '0 B';
	}
	var i = Math.floor( Math.log(size) / Math.log(1024) );
	return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export const downloadFile = file => {
	// Create a link and set the URL using `createObjectURL`
	const link = document.createElement('a');
	link.style.display = 'none';
	link.href = URL.createObjectURL(file);
	link.download = file.name;

	// It needs to be added to the DOM so it can be clicked
	document.body.appendChild(link);
	link.click();

	// To make this work on Firefox we need to wait
	// a little while before removing it.
	setTimeout(() => {
		URL.revokeObjectURL(link.href);
		link.parentNode.removeChild(link);
	}, 0);
};

export const lowercasedIncludes = (original = '', search = '') => {
	search = search?.toLowerCase?.() || search;
	if (typeof original === 'string') {
		return original?.toLowerCase?.().includes(search);
	} else if (Array.isArray(original)) {
		// Array provided includes only check for exact string from an array
		// We will extend the logic to check for substring matches if the orginial is array of strings
		return original.some(e => {
			const lowerCasedValue = e?.toLowerCase?.() || e;
			return typeof lowerCasedValue === 'string'
				? lowerCasedValue.includes(search)
				: lowerCasedValue === search;
		});
	} else {
		return false;
	}
};

export const getPlatformImage = platform => {
	const platformImage = (() => {
		try {
			return require(`assets/logos/platforms/${platform}.png`);
		} catch {
			return require('assets/logos/platforms/Hangry App.png');
		}
	})();
	return platformImage;
};

export const getBrandImage = brand => {
	const brandImage = (() => {
		try {
			return require(`assets/logos/brands/${brand}.png`);
		} catch {
			return require('assets/logos/brands/Hangry App.png');
		}
	})();
	return brandImage;
};

export const getOrderTitle = (data, isRecon = false) => {
	let title = '';
	switch (isRecon ? data.platformLabel : data.platform) {
		case 'Hangry App':
			title = data.customer?.name; break;
		case 'Shopee': {
			const shopeeIdEntry = isRecon ? data.platformOrderId : data.externalAltId;
			const isTest = shopeeIdEntry.includes(TEST_LABEL);
			title = `${isTest ? `${TEST_LABEL} ` : ''}#${(shopeeIdEntry?.split?.(' ')?.pop?.())?.slice(2)}`; break;
		}
		case 'Grab':
		case 'Gojek':
		default:
			title = isRecon ? data.platformOrderId : data.externalAltId; break;
	}
	return title;
};

export const getIndonesianTimezoneLabel = value => {
	// 0 is a valid value and are not replaced by default value
	let utcOffset = value || value === 0 ? value : dayjs().utcOffset();
	if (typeof value === 'string') {
		utcOffset = dayjs().tz(value).utcOffset();
	}
	const utcOffsetInHour = utcOffset / 60;
	switch (utcOffsetInHour) {
		case 7:
			return 'WIB';
		case 8:
			return 'WITA';
		case 9:
			return 'WIT';
		default:
			return typeof value === 'string'
				? value
				: `UTC${utcOffsetInHour >= 0 ? '+' : ''}${
            Math.round(utcOffsetInHour * 100) / 100
          }`;
	}
};