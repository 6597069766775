export const SET_USER_DATA = 'SET_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const SET_IS_SERVER_ONLINE = 'SET_IS_SERVER_ONLINE';
export const SET_ACTIVE_ORDERS = 'SET_ACTIVE_ORDERS';
export const SET_IS_FETCHING_ACTIVE_ORDER = 'SET_IS_FETCHING_ACTIVE_ORDER';
export const SET_HISTORY_DATE = 'SET_HISTORY_DATE';
export const SET_HISTORY_ORDERS = 'SET_HISTORY_ORDERS';
export const SET_IS_FETCHING_HISTORY_ORDER = 'SET_IS_FETCHING_HISTORY_ORDER';
export const SET_ORDER_DRAWER_DATA = 'SET_ORDER_DRAWER_DATA';
export const CLEAR_ORDER_DRAWER_DATA = 'CLEAR_ORDER_DRAWER_DATA';
export const SET_PLATFORMS = 'SET_PLATFORMS';
export const SET_BRANDS = 'SET_BRANDS';
export const SET_REMOTE_CONFIG = 'SET_REMOTE_CONFIG';
export const SET_TURN_OFF_REASONS = 'SET_TURN_OFF_REASONS';
export const SET_ELECTRON_DATA = 'SET_ELECTRON_DATA';
export const CLEAR_ELECTRON_DATA = 'CLEAR_ELECTRON_DATA';
export const SET_OUTLET_ON_OFF_REASONS_DURATION = 'SET_OUTLET_ON_OFF_REASONS_DURATION';