const Tabs = theme => ({
	tabsListWrapper: {
		borderBottom: `1px solid ${theme.colors.light.neutral.border} !important`,
	},
	tabControl: {
		height: 48,
		position: 'relative',
		color: `${theme.colors.light.text.secondary} !important`,
		border: 'unset !important',
	},
	tabActive: {
		color: `${theme.colors.light.primary.default} !important`,
		'&::after': {
			content: '""',
			position: 'absolute',
			left: 0,
			bottom: 1,
			width: '100%',
			height: 2,
			background: theme.colors.light.primary.default,
		},
	},
	body: {
		paddingTop: 24,
	},
});

export {Tabs};