import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	modalCard: {
		width: 400,
		padding: '0 !important',
		// padding: '24px !important',
	},
	modalBody: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	changeOutletContainer: {
		borderTop: `1px solid ${theme.colors.light.neutral.border}`,
		width: '100%',
		maxHeight: 600,
		padding: '8px 24px',
		overflow: 'auto',
		boxSizing: 'border-box',
	},
}));
