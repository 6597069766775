import {get, isString, isFunction, isObject} from 'lodash';
import axios from 'axios';

// Local utils
import crashLogger from 'utils/logger';
import {ERROR_CODES} from '../utils/constants';
import AuthUtils from 'utils/auth';

export const REQUEST_ENV = {
	ORDERHUB_API_HOST: process.env.REACT_APP_ORDERHUB_API_HOST,
};

export const defaultState = {
	loading: false,
	error: null,
};

axios.interceptors.response.use(response => response, error => {
	const status = error?.response?.status;
	const errorCode = error?.response?.data?.errorCode;
	const message = error?.response?.data?.message || '';
	const url = error?.response?.config?.url || '';
	const skipLogging =
		status === 401 ||
		[
			ERROR_CODES.AUTH_INVALID,
			ERROR_CODES.AUTH_LOCKED,
			ERROR_CODES.AUTH_INACTIVE,
		].includes(errorCode) ||
		(url.includes('/resto/order/process') && message === 'Order already processed');
	if (!skipLogging) {
		let severity = 'error';
		if (
			get(error, 'message') === 'Network Error' ||
			get(error, 'code') === 'ECONNABORTED'
		) {
			severity = 'warning';
		}
		crashLogger.error(error, severity);
	}
	if (status === 401 && !['/auth', '/admin'].includes(window.location.pathname)) {
		AuthUtils
			.revokeCurrentSession()
			.catch(() => null)
			.finally(() => window.location.reload());
	}
	return Promise.reject(error);
});

const request = async (param1, param2) => {
	const isFunctionBasedComponent = isFunction(param1)
		? param1
		: isFunction(param2)
			? param2
			: null;
	const isClassBasedComponent =
    (isString(get(param1, 'key')) &&
      typeof get(param1, 'component') === 'object') ||
    (isString(get(param2, 'key')) &&
      typeof get(param2, 'component') === 'object');
	const axiosBody =
    isString(get(param1, 'url')) && isString(get(param1, 'method'))
    	? param1
    	: isString(get(param2, 'url')) && isString(get(param2, 'method'))
    		? param2
    		: null;
	try {
		if (isFunctionBasedComponent) {
			isFunctionBasedComponent({
				loading: true,
				error: null,
			});
		} else if (isClassBasedComponent) {
			// TODO: class based component stateful request
		}
		const response =
      isObject(axiosBody) &&
      (
      	await axios.request({
      		timeout: 30000,
      		...axiosBody,
      	})
      ).data;
		if (isFunctionBasedComponent) {
			isFunctionBasedComponent({
				loading: false,
				error: null,
			});
		} else if (isClassBasedComponent) {
			// TODO: class based component stateful request
		}
		return response;
	} catch (error) {
		if (isFunctionBasedComponent) {
			isFunctionBasedComponent({
				loading: false,
				error: error,
			});
		} else if (isClassBasedComponent) {
			// TODO: class based component stateful request
		}
		throw error;
	}
};

export default request;
