import {createStyles} from '@mantine/core';

export const useStyles = createStyles(() => ({
	tabsList: {
		gap: 16,
	},
	tabControl: {
		padding: '0 12px',
		height: 48,
	},
	tabControlGrow: {
		maxWidth: '240px !important',
	},
	badge: {
		cursor: 'pointer',
	},
	shake: {
		WebkitAnimation: 'kf_shake 2000ms cubic-bezier(0.22, 1, 0.36, 1) 0s infinite normal forwards',
		MozAnimation: 'kf_shake 2000ms cubic-bezier(0.22, 1, 0.36, 1) 0s infinite normal forwards',
		OAnimation: 'kf_shake 2000ms cubic-bezier(0.22, 1, 0.36, 1) 0s infinite normal forwards',
		'@-webkit-keyframes kf_shake': {
			'50%,100%': {transform: 'translateX(0)'},
			'55%,65%,75%,85%': {transform: 'translateX(-2px)'},
			'60%,70%,80%': {transform: 'translateX(2px)'},
			'90%': {transform: 'translateX(1px)'},
			'95%': {transform: 'translateX(-1px)'},
		},
		'@-moz-keyframes kf_shake': {
			'50%,100%': {transform: 'translateX(0)'},
			'55%,65%,75%,85%': {transform: 'translateX(-2px)'},
			'60%,70%,80%': {transform: 'translateX(2px)'},
			'90%': {transform: 'translateX(1px)'},
			'95%': {transform: 'translateX(-1px)'},
		},
		'@-o-keyframes kf_shake': {
			'50%,100%': {transform: 'translateX(0)'},
			'55%,65%,75%,85%': {transform: 'translateX(-2px)'},
			'60%,70%,80%': {transform: 'translateX(2px)'},
			'90%': {transform: 'translateX(1px)'},
			'95%': {transform: 'translateX(-1px)'},
		},
	},
}));